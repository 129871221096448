<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:actions>
        <a-btn-refresh @click="$refs.table.updateData()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать документ'" v-if="getAccess('documents.loanGet.create') && false" @click="createNew()" />
      </template>
      <portal to="v-main">
        <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="idEdit" />
      </portal>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="getModelList(m)"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @update-data="updateData($event)"
        @click="
          counter += 1;
          onDetectClick($event);
        "
      >
        <template v-slot:footer>
          <div class="f-table-footer">
            <div class="f-table-row">
              <div style="margin: 6px 6px" v-if="$refs.table">
                Показано: {{ $refs.table.pager.count }}, На сумму: {{ (total_price || 0).toLocaleString() }}
              </div>
            </div>
          </div>
        </template>
      </a-table-f-api>
      <popup-window v-if="infoSnack" :type="3" :data="infoData" :model="infoModel" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </div>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect, popupMenu } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect, popupMenu],
  components: {
    ViewItem: () => import("./views/loanGetView"),
  },
  data() {
    return {
      idEdit: 0,
      idItemShow: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.documentLoanGet,
      url: "/accounting/doc/getting_loan",

      total_price: null,
      showGoodFind: false,
    };
  },
  created() {
    this.$root.title = this.m.title;
  },
  computed: {
    defaults() {
      return {
        sort: { key: "date_doc", order: "DESC" },
        filters: {},
        paramName: "documentLoanGet",
        where: this.getAccess("documents.loanGet.viewAll") ? {} : { createdby_id: this.$root.profile.id },
      };
    },
    configHead() {
      return this.m.config.default || {};
    },
    permit() {
      return this.getAccess("menu.documentLoanGet");
    },
    infoSnack() {
      if (this.keysModified) {
      }
      let res = this.keys?.ctrl;
      this.infoData = null;
      this.infoModel = null;
      if (res) this.showOrderDetail();
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    async showOrderDetail() {
      let id = this.selectedRows[0];
      if (!id) return;
      let data;
      let model;
      const d = await this.$axios.get(this.url + "/" + id);
      if (d.data && d.data.data) {
        let data = d.data.data.data_table;
        model = this.m.listDetail.filter((el) => ["name", "amount", "price_real"].includes(el.name));
        this.infoData = data;
        this.infoModel = model;
      }
    },
    onSingleClick(d) {
      if (d.field?.isMenu) {
        if (d.field.name == "order_for") {
          let name = d.row.type == 1 ? "object" : d.row.type == 2 ? "office" : "storage";

          this.showPopupMenu(name, { id: d.row.object_code }, d.event);
        } else this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    onClickRow(d) {
      this.idItemShow = d.row.id;
      return;
      this.$router.push({ name: "ordersGoods_view", params: { id: d.row.id } });
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    updateData(v) {
      // this.getTotal(v.filters);
    },
    async getTotal(filter) {
      let resp = await this.$axios.post(this.url + "/report", {
        select: "count(1) as total_count, SUM(goods_table.price_real) AS total_price_real",
        where: filter,
        leftJoin: ["accounting_doc_order_goods_table goods_table ON goods_table.parent_id = accounting_doc_order_goods.id"],
      });
      this.total_price = resp.data.data[0].total_price_real || null;
    },
  },
};
</script>
